<template>
    <div class="privacy">
        <span class="privacy-top">面具约会-隐私政策</span><br/>
        <br/>
        广州龙骑士网络有限公司（以下简称“我们”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，我们制定本《面具约会隐私政策》（下称“本政策 /本隐私权政策”）并提醒您：<br/>
        <br/>
        在使用面具约会各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用面具约会各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过面具约会提供的各种联系方式与我们联系。<br/>
        <br/>
        本隐私权政策部分将帮助您了解以下内容：<br/>
        一、我们如何收集和使用您的个人信息<br/>
        二、我们如何共享、转让、公开披露您的个人信息<br/>
        三、我们如何保护您的个人信息<br/>
        四、您如何管理您的个人信息<br/>
        五、我们如何处理未成年人的个人信息<br/>
        六、您的个人信息如何在全球范围转移<br/>
        七、本隐私权政策如何更新<br/>
        八、如何联系我们<br/>
        <br/>
        <span class="title title-big">一、我们如何收集和使用您的个人信息</span><br/>
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，我们会出于本政策所述的以下目的，收集和使用您的个人信息：<br/>
        <span class="title">1、帮助您成为我们的用户</span><br/>
        1.1、为完成创建账号，以便我们为您提供服务，您需要提供以下信息：您的昵称、头像、性别、出生年月日、手机号码、微信号、身高、体重、职业<br/>
        1.2、您提供的上述信息将在您使用本服务期间持续授权我们使用。在您主动注销账号时，我们将根据适用法律法规的要求尽快使其匿名或删除您的个人信息<br/>
        <br/>
        <span class="title">2、向您提供商品或服务</span><br/>
        为向您提供完善的功能，我们会收集关于您使用功能的信息并将这些信息进行关联，这些信息包括：<br/>
        <span class="title">2.1、设备信息：</span><br/>
        我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息<br/>
        a、包括但不限于IMEI、IMSI、设备MAC地址、软件列表、设备序列号、android ID：判断并确认用户唯一标识，为防作弊功能基础；如：判断识别：用户用同一个设备申请注册多个账号，在平台发布违规内容时；平台可以根据用户唯一标识，将用户封禁；以此保障正常用户产品体验<br/>
        b、软件安装列表：当用户点击微信支付时，需要判断用户是否已经安装微信，如果未安装微信，提醒用户下载微信；因此需要软件安装列表，查看是否装微信<br/>
        c、短信：APP提供了短信验证码登录功能，需要获取到用户的验证码短信<br/>
        d、通讯录：APP提供了一键登录功能，因此需要获取并显示用户的手机号<br/>
        <br/>
        <span class="title">2.2、我们的产品集成友盟+SDK，友盟+SDK需要收集以下信息：</span><br/>
        您的设备MAC地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息），以提供统计分析服务，提供基础反作弊能力。<br/>
        <br/>
        <span class="title">2.3、我们的产品集成了一键登录SDK，需要收集以下信息才能使用一键登录功能：</span><br/>
        a、个人基础资料：注册手机号码、本机号码<br/>
        b、网络身份标识信息：网络地址<br/>
        c、个人常用设备信息：手机设备类型、硬件厂商、国际移动设备识别码、运营商类型、网络类型、SIM卡数量、国际移动用户识别码、网络地址、屏幕尺寸、屏幕分辨率<br/>
        <br/>
        <span class="title">2.4、设备所在位置相关信息：</span><br/>
        a、Idiv 地址、GdivS位置以及能够提供相关信息的Wi-Fi 接入点、蓝牙和基站等传感器信息，因我们提供的服务系基于地理位置提供的移动社交服务<br/>
        b、您可以在查看附近的人时，授权我们获取设备位置、或者拒绝授权；拒绝授权后，不影响其它功能正常使用<br/>
        <br/>
        <span class="title">2.5、日志信息：</span><br/>
        当您使用我们提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您使用功能记录、Idiv地址、访问日期和时间等。<br/>
        请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。<br/>
        <br/>
        <span class="title">3、为您提供安全保障</span><br/>
        为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或面具约会相关协议规则的情况，我们可能使用或整合您的用户信息、设备信息、有关网络日志取得您授权或依据法律共享的信息，来综合判断您账户风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br/>
        <br/>
        <span class="title">4、其他用途</span><br/>
        我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。<br/>
        <br/>
        <span class="title">5、征得授权同意的例外</span><br/>
        根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：<br/>
        5.1、与国家安全、国防安全有关的；<br/>
        5.2、与公共安全、公共卫生、重大公共利益有关的；<br/>
        5.3、与犯罪侦查、起诉、审判和判决执行等有关的；<br/>
        5.4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br/>
        5.5、所收集的个人信息是您自行向社会公众公开的；<br/>
        5.6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
        5.7、根据您的要求签订合同所必需的；<br/>
        5.8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br/>
        5.9、为合法的新闻报道所必需的；<br/>
        5.10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br/>
        5.11、法律法规规定的其他情形。<br/>
        如我们停止运营面具约会产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。<br/>
        <br/>
        <span class="title title-big">二、我们如何共享、转让、公开披露您的个人信息</span><br/>
        <span class="title">1、共享</span><br/>
        我们不会与面具约会服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br/>
        <br/>
        <span class="title">2、转让</span><br/>
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。<br/>
        <br/>
        <span class="title">3、公开披露</span><br/>
        我们仅会在以下情况下，公开披露您的个人信息：<br/>
        3.1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；<br/>
        3.2、如果我们确定您出现违反法律法规或严重违反面具约会相关协议规则的情况，或为保护面具约会及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或面具约会相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及面具约会已对您采取的措施。<br/>
        <br/>
        <span class="title">4、共享、转让、公开披露个人信息时事先征得授权同意的例外</span><br/>
        以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br/>
        1、与国家安全、国防安全有关的；<br/>
        2、与公共安全、公共卫生、重大公共利益有关的；<br/>
        3、与犯罪侦查、起诉、审判和判决执行等有关的；<br/>
        4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
        5、您自行向社会公众公开的个人信息；<br/>
        6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br/>
        根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。<br/>
        <br/>
        <span class="title title-big">三、我们如何保护您的个人信息安全</span><br/>
        1、我们已采取符合业界通用解决方案、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br/>
        2、我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。<br/>
        3、我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br/>
        如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络面具约会客服，以便我们根据您的申请采取相应措施。<br/>
        请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在个人相册上传照片、填写的个人简介。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。<br/>
        <br/>
        <span class="title title-big">四、您如何管理您的个人信息</span><br/>
        您可以通过以下方式访问及管理您的个人信息：<br/>
        <span class="title">1、访问您的个人信息</span><br/>
        您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：<br/>
        个人资料—如果您希望访问或编辑您个人资料中的昵称、头像、出生年月日、职业、身高、体重、简介，您可以通过“个人中心-编辑个人资料”执行此类操作。<br/>
        如果您无法通过上述路径访问该等个人信息，您可以随时通过面具约会客服与我们取得联系。我们将在30天内回复您的访问请求。<br/>
        对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（五）响应您的上述请求”中的相关安排向您提供。<br/>
        <br/>
        <span class="title">2、更正或补充您的个人信息</span><br/>
        当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。<br/>
        <br/>
        <span class="title">3、删除您的个人信息</span><br/>
        您可以通过本条“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。<br/>
        在以下情形中，您可以向我们提出删除个人信息的请求：<br/>
        3.1、如果我们处理个人信息的行为违反法律法规；<br/>
        3.2、如果我们收集、使用您的个人信息，却未征得您的明确同意；<br/>
        3.3、如果我们处理个人信息的行为严重违反了与您的约定；<br/>
        3.4、如果您不再使用我们的产品或服务，或您主动注销了账号；<br/>
        3.5、如果我们永久不再为您提供产品或服务。<br/>
        若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。<br/>
        当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br/>
        <br/>
        <span class="title">4、个人信息主体注销账户</span><br/>
        您可以自行联系官方客服，提交账户注销申请。在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。<br/>
        <br/>
        <span class="title">5、响应您的上述请求</span><br/>
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br/>
        我们将在30天内做出答复。如您不满意，还可以通过面具约会客服发起投诉。<br/>
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。<br/>
        在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br/>
        5.1、与国家安全、国防安全有关的；<br/>
        5.2、与公共安全、公共卫生、重大公共利益有关的；<br/>
        5.3、与犯罪侦查、起诉、审判和执行判决等有关的；<br/>
        5.4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br/>
        5.5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br/>
        5.6、涉及商业秘密的。<br/>
        <br/>
        <span class="title title-big">五、我们如何处理未成年人的个人信息</span><br/>
        本平台禁止未成年人创建自己的用户账户，用户出生年月日限制了仅超过18岁用户可以注册；。<br/>
        <br/>
        <span class="title title-big">六、您的个人信息如何在全球范围转移</span><br/>
        我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：<br/>
        1、法律法规有明确规定；<br/>
        2、获得您的明确授权；<br/>
        针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。<br/>
        <br/>
        <span class="title title-big">七、本隐私权政策如何更新</span><br/>
        我们的隐私权政策可能变更。<br/>
        未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括我们会通过面具约会公示的方式进行通知甚至向您提供弹窗提示）。<br/>
        本政策所指的重大变更包括但不限于：<br/>
        1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br/>
        2、我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；<br/>
        3、个人信息共享、转让或公开披露的主要对象发生变化；<br/>
        4、您参与个人信息处理方面的权利及其行使方式发生重大变化；<br/>
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br/>
        6、个人信息安全影响评估报告表明存在高风险时。<br/>
        我们还会将本隐私权政策的旧版本存档，供您查阅。<br/>
        <br/>
        <span class="title title-big">八、如何联系我们</span><br/>
        您可以通过以下方式与我们联系，我们将在30天内回复您的请求：<br/>
        1、如对本政策内容有任何疑问、意见或建议，您可通过面具约会在线客服与我们联系；<br/>
        2、如发现个人信息可能被泄露，您可通过面具约会在线客服投诉举报；<br/>
        如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。<br/>
        <br/>
        如有任何问题，可以联系官方邮箱反馈：lqs888lqs@163.com<br/>
        广州龙骑士网络有限公司<br/>
        <br/>
    </div>
</template>

<script>
export default {
    name: "Privacy"
}
</script>

<style lang="scss" scoped>
.privacy {
    text-align: left;
    padding: 15px 15px;
    font-size: 14px;
    line-height: 25px;
    &-top {
        font-size: 25px;
        font-weight: bold;
        line-height: 35px;
    }
    &-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 35px;
    }
    .title {
        font-weight: bold;
    }
    .title-big {
        font-size: 16px;
    }
}
</style>
